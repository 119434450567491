import React from 'react';
import { LogoLoader } from '../../common/LogoLoader/LogoLoader';

// components
//import { VideoModal } from "../../modals";
//import { Banner } from "./components";

export const Home = () => {
  //const [showVideo, setShowVideo] = useState(false);
  return (
    <>
    <LogoLoader />
    {/*
    <main>
      {showVideo && <VideoModal setShowVideo={setShowVideo} src={'https://www.youtube.com/embed/_UYog3RuaNQ'} />}
      <Banner setShowVideo={setShowVideo} />
    </main>
  */}
  </>
  )
}